import React from "react"

import {
  Footer,
  Header,
  Layout,
  SEO,
  SectionBenefitsDedicatedServers,
  SectionOfferDedicatedServers,
} from "../components"

const DedicatedPage = () => (
  <Layout
    header={<Header />}
    footer={(
      <>
        <SectionBenefitsDedicatedServers />
        <Footer />
      </>
    )}
  >
    <SEO title={'Serwery dedykowane'} />
    <SectionOfferDedicatedServers />
  </Layout>
)

export default DedicatedPage
